import PropTypes from 'prop-types';
import React from 'react';

import B2B from '@/components/B2B/B2B';
import Footer from '@/components/Footer/Footer';
import PageBreadcrumbs from '@/components/PageBreadcrumbs/PageBreadcrumbs';
import PageHeader from '@/components/PageHeader/PageHeader';
import { breadcrumbsPropType } from '@/constants/propTypes';

import { Container, ContentContainer } from './default.style';

const DefaultLayout = ({
  children,
  navigationHidden,
  basketHidden,
  languagePagePickerHidden,
  breadcrumbs,
}) => {
  return (
    <>
      <Container>
        <ContentContainer>
          <PageHeader
            navigationHidden={navigationHidden}
            basketHidden={basketHidden}
            languagePagePickerHidden={languagePagePickerHidden}
          />
          <PageBreadcrumbs breadcrumbs={breadcrumbs} />
          {children}
        </ContentContainer>
        <Footer />
      </Container>
      <B2B />
    </>
  );
};

DefaultLayout.propTypes = {
  /** Content displayed */
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  navigationHidden: PropTypes.bool,
  basketHidden: PropTypes.bool,
  languagePagePickerHidden: PropTypes.bool,
  breadcrumbs: breadcrumbsPropType,
};

DefaultLayout.defaultProps = {
  navigationHidden: false,
  basketHidden: false,
  languagePagePickerHidden: false,
  breadcrumbs: null,
};

export default DefaultLayout;
